import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import CarteLienVersGalerie from "../../../composants/navigation/CarteLienVersGalerie"
import LayoutEN from "../../../composants/layout/en/LayoutEN"

import { obtenirPage } from "../../../js/client-es/utils"
import fragmentsGaleries from "../../../js/client-es/fragments/fragments-galeries"

export const requete = graphql`
  {
    pageYamlIndexGaleries: allPagesCompilationYaml(
      filter: { code: { eq: "indexGaleriesEN" } }
    ) {
      ...FragmentPageYaml
    }
    galeriesYamlCielProfond: allGaleriesCompilationYaml(
      filter: {
        code: {
          in: [
            "topEN"
            "nebuleusesCouleursNaturellesEN"
            "nebuleusesPaletteHubbleEN"
            "voieLacteeEN"
            "galaxiesEN"
            "zoomsEt360DegresEN"
            "phenomenesEN"
            "auroresBorealesEN"
          ]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentGalerieYaml
    }
  }
`

export default function IndexGalerie() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageYamlIndexGaleries")

  return (
    <LayoutEN page={page}>
      {resultatsRequete.galeriesYamlCielProfond.nodes.map(g => {
        return <CarteLienVersGalerie key={g.code} galerie={g} />
      })}
    </LayoutEN>
  )
}
